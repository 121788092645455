/*.spirited-highlight {
  background-color: #fffed9;
}*/

/* Selection menu */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.tooltip {
  position: absolute;
  /*pointer-events: none;*/
  z-index: 20;
  background: black;
/*  border: 1px solid silver; */
  border-radius: 7px;
  padding: 2px 10px;
  margin-bottom: 7px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 1;
}

.tooltip-all:after {
  content: "";
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 7px 0 7px;
  border-color: #000000 transparent transparent transparent;
  z-index: 100;
}

.tooltip-ios:after {
  content: "";
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 65px 5px 0 5px;
  border-color: #000000 transparent transparent transparent;
}

.suggestion-menu {
  position: absolute;
}

.editor-image-group {
  margin-bottom: 20px;
}

.ProseMirror .has-no-sibling:after {
  counter-increment: prosemirror-footnote;
  /* content: '[' counter(prosemirror-footnote) ']'; */
  content: '[' attr(number) ']';

}

.ProseMirror .has-left-sibling:after {
  
  counter-increment: prosemirror-footnote;
  content: counter(prosemirror-footnote) ']';
}

.ProseMirror .has-right-sibling:after {
  counter-increment: prosemirror-footnote;
  content: '[' counter(prosemirror-footnote) ', ';
}

.ProseMirror .has-left-sibling.has-right-sibling:after {
  counter-increment: prosemirror-footnote;
  content: counter(prosemirror-footnote) ', ';
}

.ProseMirror .duplicate-footnote {
  color: rgb(184, 0, 0);
}

.ProseMirror footnote {
  color: #1e88e5;
  background-color: inherit;
} 

.article-body *::-moz-selection  {
  background-color: rgb(252,238,172);
  color: black;
}
.article-body *::selection  { 
  background-color: rgb(252,238,172);
  color: black;
}
.article-body *::-webkit-selection { 
  background-color: rgb(252,238,172);
  color: black;
}

.spirited-highlight {
  padding-top: 2px;
  padding-bottom: 2px;
}

span.spirited-character {
  color: #1e88e5;
  cursor:  pointer;
}

span.spirited-chacater:hover {
  color: #b8b8b8;
}

span.spirited-character::selection {
  background-color: rgb(252,238,172);
  color: black;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

div.image-div:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.5);
}

/*.article-text {
  width: 100%;
}*/

.article-text p:first-child {
  margin-top: 0;
}

/* .article-text blockquote {
  border-left: 3px solid #d8d8d8;
} */

.article-text h2 {
  margin-top: 20px;
  margin-bottom: 10px;
/*  font-weight: 500;*/
}

.article-text li > p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.article-text a {
  text-decoration: none;
  color: #1e88e5;
}

/*.published-article ::selection {
  background: rgb(252, 238, 172);
}
*/
.grecaptcha-badge { visibility: hidden; }

/* Quote Editor Styles */

.quoted-text p {
  margin-block-end: 0.5em;
}

.quoted-text p.contains-text:after {
  content: '”';
  display: inline-block;
  position: relative;
}

.quoted-text p.contains-text:before {
  content: '“';
  position: absolute;
  text-indent: -.4125em;
  font-size: 1.5em;
}

.quoted-text p.empty-node:before {
  content: '“Quote text';
  position: absolute;
  text-indent: -.4125em;
}

.plain-text p.empty-node:before {
  content: 'Enter text';
}

/* Finalized Quote Styles */

.final-quoted-text p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
}

.final-quoted-text p:after {
  content: '”';
}

.final-quoted-text p:before {
  content: '“';
  position: absolute;
  text-indent: -.4125em;
  font-size: 1.5em;
}

.final-text p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
}

.quote-display {
  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  max-height: 500px;
  overflow-y: hidden
}

/*.comment {
  font-family: 'Roboto';
  font-size: 16px;
}*/

.comment p {
  margin-block-start:  0.5em;
  margin-block-end:  0.5em;
}

.comment p:first-child.empty-node:before {
  content: 'Share your thoughts';
}

.hashtag, .mention {
  text-decoration: none;
  color: #3f51b5; 
}

.over-char-limit {
  background-color: #ffbfba;
}

.fadeIn {
  -webkit-animation: fadeIn 1s ease-in-out 0s;
  -moz-animation: fadeIn 1s ease-in-out 0s;
  -o-animation: fadeIn 1s ease-in-out 0s;
   animation: fadeIn 1s ease-in-out 0s;
}

